import api from './config';

export const getGig = async (id) => {
	return (await api.get(`/gigs/${id}`)).data;
};

export const getAllGigs = async () => {
	return (await api.get(`/gigs`)).data;
};

export const getActiveGigs = async (theDate) => {
	return (await api.get(`/gigs/on/${theDate.getFullYear()}-${theDate.getMonth() + 1}-${theDate.getDate()}`)).data;
};

export const getSummaryForDay = async (theDate) => {
	return (await api.get(`/gigs/summary/for/${theDate.getFullYear()}-${theDate.getMonth() + 1}-${theDate.getDate()}`)).data;
};

export const getSummary = async () => {
	return (await api.get(`/gigs/summary/all`)).data;
};

export const createGig = async (gig) => {
	return api.post(`/gigs`, {
		name: gig.name,
		startDate: gig.startDate,
		endDate: gig.endDate
	}).then((response) => {
		return response.data;
	});
};

export const updateGig = async (gig) => {
	return await api.put(`/gigs/${gig.id}`, {
		name: gig.name,
		startDate: gig.startDate,
		endDate: gig.endDate,
		allocationPercentage: gig.allocationPercentage,
		allocationHours: gig.allocationHours,
		categoryId: gig.categoryId
	}).then((response) => {
		return response.data;
	});
};

export const deleteGig = async (id) => {
	return (await api.delete(`/gigs/${id}`)).data;
};
