import React, { useState, useEffect } from "react";
import moment from 'moment';
import { gigApi } from "../../api";

const GigReport = () => {
	const [gigs, setGigs] = useState([]);

	useEffect(() => {
		fetchGigs();
	}, []);

	async function fetchGigs() {
		await gigApi.getSummary()
			.then((data) => {
				setGigs(data.summaries);
			});
	};

	return (
		<div className="container>">
			<div className="row">
				<h3>Gig Summary</h3>
			</div>
			<div className="row">
				<table className="table table-striped table-bordered table-sm">
					<caption>All engagements with their utilization and effects on utilization.</caption>
					<thead className="thead-dark">
						<tr>
							<th className="text-center" scope="col">&nbsp;</th>
							<th className="text-center" scope="col">Start</th>
							<th className="text-center" scope="col">End</th>
							<th className="text-center" scope="col">Hours Allocated</th>
							<th className="text-center" scope="col">Hours Used</th>
							<th className="text-center" scope="col">Utilization</th>
							<th className="text-center" scope="col">Hours Per Day</th>
						</tr>
					</thead>
					<tbody>
						{gigs.map((value, index) => (
							<tr key={value.id}>
								<td className="text-left">{value.name}</td>
								<td className="text-center text-nowrap">
									{moment(value.startDate).format("YYYY-MM-DD")}
								</td>
								<td className="text-center text-nowrap">
									{moment(value.endDate).format("YYYY-MM-DD")}
								</td>
								<td className="text-end">{value.hoursAllocated.toFixed(2)}</td>
								<td className="text-end">{value.hoursUsed.toFixed(2)}</td>
								<td className="text-end">{value.utilization.toFixed(2)}%</td>
								<td className="text-end">{value.hoursPerDayForGig.toFixed(2)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div >
		</div >
	);
};

export default GigReport;