import api from './config';

export const getCategories = async () => {
	return (await api.get(`/categories/lookup`)).data;
};

export const getAllCategories = async () => {
	return (await api.get(`/categories`)).data;
};

export const getCategoriesInRange = async (start, end) => {
	return api.post(`/categories/between/${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}/${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`)
		.then((response) => {
			return response.data;
		});
};

export const createCategory = async (category, id) => {
	return api.post(`/categories`, {
		name: category,
		parentId: id
	}).then((response) => {
		return response.data;
	});
};

export const updateCategory = async (entry) => {
	return await api.put(`/categories/${entry.id}`, {
		Name: entry.name
	}).data;
};

export const toogleCategoryActive = async (id) => {
	return api.put(`/categories/${id}/toggle-active`)
		.then((response) => {
			return response.data;
		});
};

export const toogleCategoryUtilizationByDefault = async (id) => {
	return api.put(`/categories/${id}/toggle-utilization-by-default`)
		.then((response) => {
			return response.data;
		});
};