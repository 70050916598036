import axios from "axios";
import { auth } from '../firebase';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json',
		"Accept": "application/json",
	}
});

instance.interceptors.request.use(
	async config => {
		const user = auth.currentUser;
		if (user) {
			const token = await user.getIdToken();
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	response => response,
	async error => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			try {
				const user = auth.currentUser;
				if (user) {
					const token = await user.getIdToken(true);
					originalRequest.headers['Authorization'] = `Bearer ${token}`;
					return instance(originalRequest);
				}
			} catch (err) {
				console.error('Token refresh failed:', err);
			}
		}
		return Promise.reject(error);
	}
);

export default instance;