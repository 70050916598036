import api from './config';

export const getEntries = async (start, end) => {
	return await api.post(`/entries/between/${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}/${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`, {})
		.then((response) => {
			return response.data;
		});
};

export const getEntriesForDay = async (start) => {
	return await api.post(`/day`, {
		"year": start.getFullYear(),
		"month": start.getMonth() + 1,
		"day": start.getDate()
	})
		.then((response) => {
			return response.data;
		});
};

export const getEntryById = async (id) => {
	return await api.get(`/entries/${id}`).data;
};

export const createEntry = async (entry) => {
	return await api.post(`/entries`, {
		OnDate: entry.onDate,
		Description: entry.description,
		CategoryId: entry.categoryId,
		Duration: entry.duration,
		IsUtilization: entry.billable,
		Notes: entry.notes
	})
		.then((response) => {
			return response.data;
		});
};

export const updateEntry = async (entry) => {
	return await api.put(`/entries/${entry.id}`, {
		OnDate: entry.onDate,
		Description: entry.description,
		CategoryId: entry.categoryId,
		Duration: entry.duration,
		IsUtilization: entry.billable,
		Notes: entry.notes
	}).then((response) => { return response.data; });
};

export const deleteEntry = async (id) => {
	return await api.delete(`/entries/${id}`);
};

export const saveUtilization = async (entry) => {
	return await api.put(`/entries/${entry.id}`, {
		OnDate: entry.onDate,
		IsUtilization: entry.billable
	}).then((response) => { return response.data; })
};

export const saveDuration = async (entry) => {
	return await api.put(`/entries/${entry.id}`, {
		OnDate: entry.onDate,
		Duration: entry.duration
	}).then((response) => { return response.data; });
};

export const saveDescription = async (entry) => {
	return await api.put(`/entries/${entry.id}`, {
		OnDate: entry.onDate,
		Description: entry.description
	}).then((response) => { return response.data; });
};

export const saveCategory = async (entry) => {
	return await api.put(`/entries/${entry.id}`, {
		OnDate: entry.onDate,
		CategoryId: entry.categoryId
	}).then((response) => { return response.data; });
};