// import { useNavigate } from "react-router-dom";
import useUserProfile from "../../useUserProfile";


const Profile = () => {

	const [userProfile, refreshUserProfile] = useUserProfile();
	
	if (!userProfile) {
		refreshUserProfile();
		return (
			<div>Loading...</div>
		)
	}

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-4 text-center">
					<h3>Profile for {userProfile.name ?? '[No name specified]'}</h3>
					<p>Name: {userProfile.name ?? '[No Name Specified]'}</p>
					<p>Email: {userProfile.email}</p>
				</div>
			</div>
		</div>
	)
}

export default Profile;