import { useEffect, useState } from 'react';
import { reportingApi } from './api';

const useUserProfile = () => {
	const [userProfile, setUserProfile] = useState(() => {
		const storedProfile = localStorage.getItem('userProfile');
		return storedProfile ? JSON.parse(storedProfile) : null;
	});

	const fetchUserProfile = async () => {
		const data = await reportingApi.getProfile();
		// const data = await response.json();
		setUserProfile(data);
		localStorage.setItem('userProfile', JSON.stringify(data));
	};

	useEffect(() => {
		if (!userProfile) {
			console.log("fetching user profile");
			fetchUserProfile();
		}
	}, []);

	const refreshUserProfile = async () => {
		await fetchUserProfile();
	};

	const clearUserProfile = () => {
		setUserProfile(null);
		localStorage.removeItem('userProfile');
	};

	return [userProfile, refreshUserProfile, clearUserProfile];
};

export default useUserProfile;