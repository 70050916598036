import React from "react";

const GigSummary = ({ gigSummary }) => {
	if (gigSummary.length === 0) {
		return <></>;
	}

	return (
		<>
			<table className='table table-sm table-striped caption-top'>
				<caption>Current Gigs</caption>
				<thead>
					<tr key={"CS-231"}>
						<th>&nbsp;</th>
						<th className='text-end'>{gigSummary.totalUtilization.toFixed(2)}%</th>
						<th className='text-end'>{gigSummary.totalUtilizedHoursPerDay.toFixed(2)}</th>
					</tr>
				</thead>
				<tbody>
					{gigSummary.summaries.map((entry, index) => (
						<>
							<tr key={"CS" + index}>
								<td>{entry.name} ({entry.hoursUsed.toFixed(2)}/{entry.hoursAllocated.toFixed(2)}, {entry.percentComplete.toFixed(2)}%)</td>
								<td className='text-end'>Utilization: {entry.utilization.toFixed(2)}%</td>
								<td className='text-end'>Hours per day: {entry.hoursPerDayForGig.toFixed(2)}</td>
							</tr>
						</>
					))}
				</tbody>
			</table>
		</>
	);
}

export default GigSummary;