import React, { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import { gigApi } from "../../api";
import { categoryApi } from "../../api";

const Gigs = () => {
	const [gigs, setGigs] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		fetchCategories().then(() => {
			fetchGigs();
		});
	}, []);

	const fetchGigs = async () => {
		console.log("fetching gigs");
		try {
			gigApi.getAllGigs().then((data) => {
				setGigs(data);
			});
		} catch (error) {
			console.error(`Error Gigs`, error);
		}
	};

	const fetchCategories = async () => {
		console.log("fetching Gigs");
		try {
			categoryApi.getAllCategories().then((data) => {
				setCategories(data);
			});
		} catch (error) {
			console.error(`Error Gigs`, error);
		}
	};

	const handleNewGig = () => {
		var today = new Date();
		var amonthFromNow = new Date();
		amonthFromNow.setDate(today.getDate() + 30);

		var newGig = {
			name: "New Gig #" + Math.floor(Math.random() * 1000),
			startDate: today,
			endDate: amonthFromNow,
		};

		gigApi.createGig(newGig).then((data) => {
			fetchGigs();
		});
	};

	const handleCategoryChange = (ctl, id) => {
		console.log("Updating category for gig", id, ctl.target.value)
		var gig = gigs.filter(g => g.id === id)[0];
		gig.categoryId = ctl.target.value;

		updateGig(gig);
	};

	const handleDelete = (id) => {
		console.log("Deleting gig", id);
		gigApi.deleteGig(id).then((data) => {
			console.log("Gig deleted", data);
			fetchGigs();
		});
	};

	const handleNameChange = (ctl, id) => {
		var gig = gigs.filter(g => g.id === id)[0];

		gig.name = ctl.target.value;

		updateGig(gig);
	};

	const handleAllocationHoursChange = (ctl, id) => {
		var gig = gigs.filter(g => g.id === id)[0];

		gig.allocationHours = ctl.target.value;

		updateGig(gig);
	};

	const handleAllocationPercentageChange = (ctl, id) => {
		var gig = gigs.filter(g => g.id === id)[0];

		gig.allocationPercentage = ctl.target.value;

		updateGig(gig);
	};

	const handleStartDateChange = (date, id) => {
		var gig = gigs.filter(g => g.id === id)[0];

		gig.startDate = date;

		updateGig(gig);
	};

	const handleEndDateChange = (date, id) => {
		var gig = gigs.filter(g => g.id === id)[0];

		gig.endDate = date;

		updateGig(gig);
	};

	const updateGig = (gig) => {
		var updatedGig = {
			id: gig.id,
			name: gig.name,
			startDate: gig.startDate,
			endDate: gig.endDate,
			allocationPercentage: gig.allocationPercentage,
			allocationHours: gig.allocationHours,
			categoryId: gig.categoryId
		};

		gigApi.updateGig(updatedGig).then((data) => {
			console.log("Gig updated", data);

			const index = gigs.findIndex((g) => g.id === gig.id);
			let newGigs = [];

			gigs.forEach((g, i) => {
				if (i === index) {
					newGigs.push(data);
				} else {
					newGigs.push(g);
				}
			});

			setGigs(newGigs);
		});
	};

	return (
		<table className="table table-striped caption-top">
			<caption><h3>Gigs<button type='button' className='btn btn-light btn-sm' onClick={() => handleNewGig()}><i className='bi bi-file-plus'></i></button>
				<button type='button' className='btn btn-light btn-sm' onClick={() => fetchGigs()}><i className='bi bi-arrow-clockwise'></i></button></h3></caption>
			<thead>
				<tr>
					<th>&nbsp;</th>
					<th>Name</th>
					<th>Start Date</th>
					<th>End Date</th>
					<th>Allocation</th>
					<th>Category</th>
				</tr>
			</thead>
			<tbody>
				{gigs.map((gig) => (
					<tr key={gig.id}>
						<td>
							<button
								type="button"
								className="btn btn-outline-danger btn-sm"
								onClick={() => handleDelete(gig.id)}>
								<i className="bi bi-trash3"></i>
							</button>
						</td>
						<td>
							<input
								type="text"
								className='form-control form-control-sm'
								id='name'
								name='name'
								defaultValue={gig.name}
								onChange={(e) => handleNameChange(e, gig.id)}
								required
							/>

						</td>
						<td>
							<DatePicker
								id='selectedDate'
								name='selectedDate'
								className='form-control form-control-sm'
								selected={new Date(gig.startDate)}
								onChange={(date) => handleStartDateChange(date, gig.id)}
								required
							/>

						</td>
						<td>
							<DatePicker
								id='selectedDate'
								name='selectedDate'
								className='form-control form-control-sm'
								selected={new Date(gig.endDate)}
								onChange={(date) => handleEndDateChange(date, gig.id)}
								required
							/>
						</td>
						<td>
							<input
								type="number"
								step="1"
								className="form-control form-control-sm"
								defaultValue={gig.allocationPercentage}
								onChange={(e) => handleAllocationPercentageChange(e, gig.id)}
								min="0"
								max="100"
							/> % OR
							<input
								type="number"
								step="1"
								className="form-control form-control-sm"
								defaultValue={gig.allocationHours}
								onChange={(e) => handleAllocationHoursChange(e, gig.id)}
								min="0"
							/> HOURS
						</td>
						<td>
							<select
								className='form-select form-control form-control-sm'
								aria-label='category select'
								defaultValue={gig.categoryId}
								onChange={(e) => handleCategoryChange(e, gig.id)}
							>
								<option value="">-- Select a Category --</option>
								{categories.map((option) => (
									<option key={option.id} value={option.id}>{option.fullName}</option>
								))}
							</select>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)
};

export default Gigs;