import api from './config';

export const getWeekSummaryReport = async () => {
	return await api.post(`/report/week-summary`, {})
		.then((response) => {
			return response.data;
		});
};

export const getCategorySummaryReport = async (start, end) => {
	return await api.post(`/report/category-summary-by-week/${start.format("YYYY-MM-DD")}/${end.format("YYYY-MM-DD")}`)
		.then((response) => {
			return response.data;
		});
};

export const getDaySummaryReport = async (start, end) => {
	return await api.post(`/report/category-summary-by-day/${start.format("YYYY-MM-DD")}/${end.format("YYYY-MM-DD")}`)
		.then((response) => {
			return response.data;
		});
};

export const getProfile = async () => {
	return (await api.get(`/profile`)).data;
};
