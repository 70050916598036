import React, { useEffect } from 'react';

const Callback = () => {

	// useEffect(() => {
	// 	const processAuth = async () => {
	// 		await handleRedirectCallback();
	// 		window.location.href = '/';
	// 	};
	// 	processAuth();
	// }, [handleRedirectCallback]);

	return <div>Loading...</div>;
};

export default Callback;